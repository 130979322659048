import UserInfo from "views/UserInfo.js";
import Transactions from "views/Transactions.js";
import ConsumerLog from "views/ConsumerLog.js";
import Log from "views/Log.js";
import Settings from "views/Settings.js";
import EmployeeLog from "views/EmployeeLog.js";
import ChangePassword from "views/ChangePassword.js";
import Termination from "views/Termination.js";
import FamilyEvent from "views/FamilyEvent.js";
import Coupon from "views/Coupon.js";
import Block from "views/Block.js";
import Seal from "views/Seal.js";
import Spin from "views/Spin";
import Badge from "views/Badge";
import Merchant from "./views/Merchant";
import CheckTransaction from "views/CheckTransaction";
import EmployeeLoyalty from "views/EmployeeLoyalty";
import Mission from "views/Mission";
import GraceryStores from "views/GroceryStores";
import Eclinic from "views/Eclinic";
import CardConnection from "views/CardConnection";
import NewYear2023 from "views/NewYear2023";
import Lunar2024 from "views/Lunar2024";
// import UglyTool from "views/UglyTool.js";
// import SpringPromo from "views/SpringPromo";
// import MerchantLoyalty from "views/MerchantLoyalty";
// import NewYear from "views/NewYear";
// import Lunar from "views/Lunar";
// import Card from "views/Card";
import Lottery from "views/Lottery";
import Partner2024 from "views/Partner2024";
import ICoke from "views/ICoke";
import Factory from "views/Factory";
// import Saalindaa from "views/Saalindaa";
// import New from "views/New";
// import Lunar23 from "views/Lunar23";
// import NamrynUramshuulal from "views/NamrynUramshuulal";
// import Anniversary from "views/Anniversary";

const dashboardRoutes = [
  {
    path: "/user",
    name: "Хэрэглэгч",
    icon: "nc-icon nc-circle-09",
    component: UserInfo,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/transaction",
    name: "Гүйлгээ",
    icon: "nc-icon nc-credit-card",
    component: Transactions,
    layout: "/admin",
    view: "crm_transaction",
  },
  {
    path: "/consumer_log",
    name: "Хэрэглэгчийн түүх",
    icon: "fa fa-history fa-2x",
    component: ConsumerLog,
    layout: "/admin",
    view: "crm_consumer_log",
  },
  {
    path: "/promo_coupon",
    name: "Купон",
    icon: "fa fa-gift fa-2x",
    component: Coupon,
    layout: "/admin",
    view: "crm_promo_coupon",
  },
  {
    path: "/card_connection",
    name: "Карт",
    icon: "fa fa-car fa-2x",
    component: CardConnection,
    layout: "/admin",
    view: "crm_consumer_log",
  },
  {
    path: "/factory_game",
    name: "Factory Game",
    icon: "fa fa-gamepad fa-2x",
    component: Factory,
    layout: "/admin",
    view: "crm_user",
  },
  // {
  //   path: "/icoke2024",
  //   name: "ICoke ",
  //   icon: "fa fa-sun fa-2x",
  //   component: ICoke,
  //   layout: "/admin",
  //   view: "crm_user",
  // },
  {
    path: "/partner2024",
    name: "Partner 2024",
    icon: "fa fa-gift fa-2x",
    component: Partner2024,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/lunar2024",
    name: "Lunar 2024",
    icon: "fa fa-moon",
    component: Lunar2024,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/newYear2023",
    name: "New Year 2023",
    icon: "fa fa-snowflake",
    component: NewYear2023,
    layout: "/admin",
    view: "crm_mission",
  },
  {
    path: "/grocery_stores",
    name: "6, 8 нэр",
    icon: "fa fa-store",
    component: GraceryStores,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/eclinic",
    name: "EClinic",
    icon: "fa fa-hospital",
    component: Eclinic,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/mission",
    name: "Mission",
    icon: "fab fa-medium-m",
    component: Mission,
    layout: "/admin",
    view: "crm_mission",
  },
  {
    path: "/lottery",
    name: "Lottery",
    icon: "fas fa-ticket-alt",
    component: Lottery,
    layout: "/admin",
    view: "crm_lottery",
  },
  {
    path: "/termination",
    name: "Termination",
    icon: "fa fa-cog",
    component: Termination,
    layout: "/admin",
    view: "crm_termination",
  },
  {
    path: "/family_event",
    name: "FamilyEvent",
    icon: "fa fa-cog",
    component: FamilyEvent,
    layout: "/admin",
    view: "crm_family_event",
  },
  {
    path: "/spin",
    name: "Хүрд",
    icon: "fa fa-dot-circle fa-2x",
    component: Spin,
    layout: "/admin",
    view: "crm_spin",
  },
  {
    path: "/badge",
    name: "Badge",
    icon: "fa fa-certificate",
    component: Badge,
    layout: "/admin",
    view: "crm_badge",
  },
  {
    path: "/merchant",
    name: "Merchant",
    icon: "fas fa-warehouse",
    component: Merchant,
    layout: "/admin",
    view: "crm_merchant",
  },
  {
    path: "/check",
    name: "Check",
    icon: "far fa-check-circle",
    component: CheckTransaction,
    layout: "/admin",
    view: "crm_check",
  },
  {
    path: "/loyalty",
    name: "Loyalty",
    icon: "fas fa-hand-holding-heart",
    component: EmployeeLoyalty,
    layout: "/admin",
    view: "crm_loyalty",
  },
  {
    path: "/log",
    name: "Log",
    icon: "fa fa-bars",
    component: Log,
    layout: "/admin",
    view: "crm_mission",
  },
  // {
  //     path: "/springpromo",
  //     name: "Давхцалгүй доод үнэ",
  //     icon: "fa fa-gift fa-2x",
  //     component: SpringPromo,
  //     layout: "/admin",
  //     view: "crm_springpromo",
  // },
  // {
  //     path: "/MerchantLoyalty",
  //     name: "Merchant Loyalty",
  //     icon: "fab fa-medium-m",
  //     component: MerchantLoyalty,
  //     layout: "/admin",
  //     view: "crm_mission",
  // },
  // {
  //     path: "/autumn",
  //     name: "Autumn",
  //     icon: "fas fa-gifts",
  //     component: NamrynUramshuulal,
  //     layout: "/admin",
  //     view: "crm_autumn",
  // },
  {
    path: "/block",
    name: "Блок",
    icon: "fa fa-ban",
    component: Block,
    layout: "/admin",
    view: "crm_block",
  },
  {
    path: "/seal",
    name: "Битүүмж",
    icon: "fa fa-stamp",
    component: Seal,
    layout: "/admin",
    view: "crm_seal",
  },
  {
    path: "/settings",
    name: "Тохиргоо",
    icon: "fa fa-cog",
    component: Settings,
    layout: "/admin",
    view: "crm_settings",
  },
  {
    path: "/employee_log",
    name: "Ажилтны лог",
    icon: "fa fa-history fa-2x",
    component: EmployeeLog,
    layout: "/admin",
    view: "crm_employee_log",
  },
  {
    path: "/change_password",
    name: "Тохиргоо",
    icon: "fa fa-cog",
    component: ChangePassword,
    layout: "/admin",
    view: "crm_change_password",
  },
  // {
  //     path: "/new_year",
  //     name: "NewYear",
  //     icon: "fa fa-sleigh",
  //     component: NewYear,
  //     layout: "/admin",
  //     view: "crm_mission",
  // },
  // {
  //     path: "/saalindaa",
  //     name: "Saalindaa",
  //     icon: "fab fa-bitbucket",
  //     component: Saalindaa,
  //     layout: "/admin",
  //     view: "crm_mission",
  // },
  // {
  //     path: "/card",
  //     name: "Card",
  //     icon: "fa fa-credit-card",
  //     component: Card,
  //     layout: "/admin",
  //     view: "crm_mission",
  // },
  //     {
  //       path: "/lunar23",
  //       name: "Amazing Lunar",
  //       icon: "fab fa-affiliatetheme",
  //       component: Lunar23,
  //       layout: "/admin",
  //       view: "crm_mission",
  //   },
  // {
  //   path: "/anniversary",
  //   name: "5н жил",
  //   icon: "fa fa-gift",
  //   component: Anniversary,
  //   layout: "/admin",
  //   view: "crm_mission",
  // },
];

export default dashboardRoutes;
